import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import './Footer.scss';

const footer = (props) => {
  return (
    <div className="FooterContainer py-2 d-flex justify-content-center">
      <Nav className="Footer d-flex justify-content-around">
        <NavItem>
          <NavLink disabled href="#">
            © Smilzz {new Date().getFullYear()}
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default footer;
