import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { MdCameraAlt } from 'react-icons/md';
import { IoMdImage, IoMdImages } from 'react-icons/io';
const ICON_COLOR = '#c3c3c3';
const ICON_SELECTED_COLOR = '#fff';

const navItemsArr = [
  {
    itemKey: 'dashboard',
    itemText: 'Home',
    itemIcon: <AiFillHome style={{ color: ICON_COLOR }} />,
    itemIconSelected: <AiFillHome style={{ color: ICON_SELECTED_COLOR }} />,
  },
  {
    itemKey: 'users',
    itemText: 'Users',
    itemIcon: <FaUsers style={{ color: ICON_COLOR }} />,
    itemIconSelected: <FaUsers style={{ color: ICON_SELECTED_COLOR }} />,
    subNav: [
      {
        itemText: 'List',
        itemKey: 'users',
      },
      {
        itemText: 'Ratings',
        itemKey: 'users/ratings',
      },
    ],
  },
  {
    itemKey: 'images',
    itemText: 'Images',
    itemIcon: <IoMdImages style={{ color: ICON_COLOR }} />,
    itemIconSelected: <IoMdImages style={{ color: ICON_SELECTED_COLOR }} />,
    subNav: [
      {
        itemText: 'Create',
        itemKey: 'images/create',
      },
      {
        itemText: 'Read',
        itemKey: 'images/read',
      },
      {
        itemText: 'Update',
        itemKey: 'images/update',
      },
      {
        itemText: 'Delete',
        itemKey: 'images/delete',
      },
      {
        itemText: 'List',
        itemKey: 'images/list',
      },
    ],
  },
  {
    itemKey: 'deals',
    itemText: 'Deals',
    itemIcon: <MdCameraAlt style={{ color: ICON_COLOR }} />,
    itemIconSelected: <MdCameraAlt style={{ color: ICON_SELECTED_COLOR }} />,
    subNav: [
      {
        itemText: 'Create',
        itemKey: 'deal/create',
      },

      {
        itemText: 'List',
        itemKey: 'deals',
      },
    ],
  },
  {
    itemKey: 'galeries',
    itemText: 'Galeries',
    itemIcon: <IoMdImage style={{ color: ICON_COLOR }} />,
    itemIconSelected: <IoMdImage style={{ color: ICON_SELECTED_COLOR }} />,
    subNav: [
      {
        itemText: 'Create',
        itemKey: 'galeries/create',
      },
      {
        itemText: 'Read',
        itemKey: 'galeries/read',
      },
      {
        itemText: 'Update',
        itemKey: 'galeries/update',
      },
      {
        itemText: 'Delete',
        itemKey: 'galeries/delete',
      },
      {
        itemText: 'List',
        itemKey: 'galeries/list',
      },
    ],
  },
];

export default navItemsArr;
