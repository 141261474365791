import React from 'react';
import { withRouter } from 'react-router-dom';
import Style from './index.module.scss';
import Img from '../../../../../assets/images/PowerSmilzz.png';

const Section = () => {
  return (
    <div
      className={[
        'd-flex justify-content-center align-items-center',
        Style.Container
      ].join(' ')}
    >
      <img src={Img} className={Style.Image} alt="Smilzz Powers" />
      <h2>Nous avons le photographe qu'il vous faut.</h2>
    </div>
  );
};

export default withRouter(Section);
