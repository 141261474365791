import React from 'react';
import { withRouter } from 'react-router-dom';
import Style from './index.module.scss';
import * as ROUTES from './../../../../../constants/routes';

const Section = (props) => {
  const handleClick = () => {
    props.history.push(ROUTES.CONTACT);
  };
  return (
    <div
      className={[
        'd-flex align-items-center justify-content-center p-4 mt-5',
        Style.Container,
      ].join(' ')}
    >
      <div className={['p-5 row', Style.Content].join(' ')}>
        <div className="col-md-8">
          <h2>Une question ?</h2>
          <p>L'équipe Smilzz vous répond dans un délai moyen de 4h</p>
        </div>
        <div className="col-md-4">
          <div onClick={handleClick} className="BlueButton">
            contactez-vous
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Section);
