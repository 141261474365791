import React from 'react';
import { withRouter } from 'react-router-dom';
//import Style from './index.module.scss';
import Section01 from './components/Section01';
import Section02 from './components/Section02';
import Section03 from './components/Section03';

const Section = () => {
  return (
    <div>
      <Section01 />
      <Section02 />
      <Section03 />
    </div>
  );
};

export default withRouter(Section);
