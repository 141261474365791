// check below
// https://github.com/trendmicro-frontend/react-sidenav

import React, { useState, useEffect } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { withRouter } from 'react-router-dom';
import {
  toggleSidebarExpanded,
  setInitialSidebarExpanded,
} from '../../../../store/actions/uiActions';
import { connect } from 'react-redux';
import navItemsArr from './components/navlink';
import Styles from './index.module.scss';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { GoChevronRight } from 'react-icons/go';
const NAV_COLOR = '#c3c3c3';
const NAV_SELECTED_COLOR = '#fff';
const SIDEBAR_COLOR = '#212529';
const navWidthCollapsed = 64;
const navWidthExpanded = 240;

const Section = ({ history, location, toggleSidebarExpanded, expanded }) => {
  useEffect(() => {
    // console.log('SIDEBAR USEEFFECT SHOULD ONLY LOAD ONCE');
  }, []);

  const openedSubnavFromStorage = () => {
    return JSON.parse(localStorage.getItem('openedSubnav')) || null;
  };

  const [selected, setSelected] = useState(location.pathname.substring(1));
  const [openedSubnav, setOpenedSubnav] = useState(openedSubnavFromStorage);

  const handleSubnavClick = (event, element) => {
    if (!expanded) toggleSidebarExpanded();
    if (element.itemKey === openedSubnav) {
      localStorage.removeItem('openedSubnav');
      setOpenedSubnav('');
    } else {
      localStorage.setItem('openedSubnav', JSON.stringify(element.itemKey));
      setOpenedSubnav(element.itemKey);
    }
  };

  const isCurrentNav = (e) => {
    return e.itemKey.toLowerCase() === selected;
  };

  const handleSidebarOpen = () => {
    toggleSidebarExpanded();
  };

  const setSubnavClassName = (itemKey) => {
    if (expanded)
      return ['Subnav-001', openedSubnav === itemKey ? 'Open' : ''].join(' ');
  };

  const getBottomMarginOnNavOpening = (item) => {
    return item.subNav && expanded && openedSubnav === item.itemKey
      ? 38 * item.subNav.length + 'px'
      : 0;
  };

  const navItems = navItemsArr.map((e, idx) => (
    <NavItem
      key={idx}
      eventKey={e.itemKey}
      style={{
        marginBottom: getBottomMarginOnNavOpening(e),
      }}
      subnavClassName={setSubnavClassName(e.itemKey)}
      onClick={(event) => handleSubnavClick(event, e)}
    >
      <NavIcon>{isCurrentNav(e) ? e.itemIconSelected : e.itemIcon}</NavIcon>
      <NavText
        style={{
          color: isCurrentNav(e) ? NAV_SELECTED_COLOR : NAV_COLOR,
          visibility: expanded ? 'visible' : 'hidden',
          opacity: expanded ? 1 : 0,
        }}
        className={Styles.SidebarSelected}
      >
        {e.itemText}
      </NavText>
      {e.subNav &&
        e.subNav.map((s, idx) => (
          <NavItem key={idx} className={Styles.SubnavItem} eventKey={s.itemKey}>
            <NavText title={s.itemText}>{s.itemText}</NavText>
          </NavItem>
        ))}
    </NavItem>
  ));

  return (
    <SideNav
      className="d-flex flex-column"
      style={{
        backgroundColor: SIDEBAR_COLOR,
        minWidth: expanded ? navWidthExpanded : navWidthCollapsed,
        position: 'fixed',
      }}
      onSelect={(selected) => {
        const pathWithoutFirstString = location.pathname.substring(1);

        setSelected(selected);

        const to = '/' + selected;
        if (location.pathname !== to) {
          history.push(to);
        }
      }}
      onToggle={(expanded) => {
        toggleSidebarExpanded();
      }}
    >
      <SideNav.Nav className="mt-5" selected={selected}>
        {navItems}
      </SideNav.Nav>
      <div
        onClick={handleSidebarOpen}
        className={[
          'flex-grow-1 d-flex align-items-end',
          expanded ? 'justify-content-end' : 'justify-content-center',
          Styles.SideBottomNav,
        ].join(' ')}
      >
        <div
          className={[
            'py-4 mr-2',
            expanded ? 'mr-3' : '',
            Styles.ChevronSideBar,
            expanded ? Styles.Open : '',
          ].join(' ')}
        >
          <GoChevronRight />
        </div>
      </div>
    </SideNav>
  );
};

const mapStateToProps = (state) => {
  return {
    expanded: state.ui.expanded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebarExpanded: () => dispatch(toggleSidebarExpanded()),
    setInitialSidebarExpanded: () => dispatch(setInitialSidebarExpanded()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Section));
