import React, { useState, useEffect } from 'react';
import './App.scss';
//import Layout from './components/Layouts/Layout';
//import { Route, Switch, Redirect } from 'react-router-dom';
import { Switch, Redirect } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import * as ADMIN_ROUTES from './constants/routes-admin';
// import { withFirebase } from './hoc/Firebase';
// import lazyLoad from './hoc/LazyLoad/LazyLoad';
import Landing from './components/pages/Landing';
import * as LAZY_COMPS from './AppLazyLoads';
import {
  Layout01Route,
  Layout02Route,
  Layout03Route,
  // PrivateRoute,
  AdminRoute,
} from './hoc/Layout';
import { connect } from 'react-redux';

const App = ({ profile }) => {
  const profileFromLocalStorage = () =>
    profile.isEmpty && localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : profile;

  const [quickestProfile] = useState(profileFromLocalStorage);

  // useEffect(() => {
  //   console.log('ds useEffect empty', quickestProfile);
  // }, []);

  const getRoutes = () => {
    // Base loading route
    let routes = (
      <Switch>
        <Layout03Route
          path={ROUTES.LANDING}
          component={LAZY_COMPS.AppLoadingPage}
        />
        {/* <Redirect to={ROUTES.LANDING} /> */}
      </Switch>
    );

    // PUBLIC ROUTES

    routes = (
      <Switch>
        {/* <Layout01Route path={ROUTES.AUTH} component={LAZY_COMPS.AuthPage} /> */}
        <Layout01Route
          path={ROUTES.CONTACT}
          component={LAZY_COMPS.ContactPage}
        />

        <Layout01Route path="/" exact component={Landing} />

        <Layout01Route
          path={ROUTES.PWC_LANDING}
          component={LAZY_COMPS.PwcLandingPage}
        />
        <Layout01Route
          path={ROUTES.PWC_THANK_YOU}
          component={LAZY_COMPS.PwcThankYouPage}
        />
        <Layout01Route
          path={ROUTES.SELECTION}
          component={LAZY_COMPS.SelectionPage}
        />
        {/* <Layout01Route
          path={ROUTES.SIGN_UP}
          component={LAZY_COMPS.SignUpPage}
        /> */}
        <Layout01Route
          path={ROUTES.SIGN_IN}
          component={LAZY_COMPS.SignInPage}
        />

        {/* redirige vers landing page */}
        <Redirect to={ROUTES.LANDING} />
        <Layout03Route component={LAZY_COMPS.NoPage} />
      </Switch>
    );

    return routes;
  };

  const [routes, setRoutes] = useState();

  useEffect(() => {
    const isNotLoggedIn = !quickestProfile.uid;

    setRoutes(getRoutes());
  }, [quickestProfile]);

  return <React.Fragment>{routes}</React.Fragment>;
};

const mapStateToProps = (state) => {
  return {
    //uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(App);
