// export const toggleSidebarExpanded = () => {
//     return (dispatch, getState, getFirebase) => {
//       dispatch({ type: 'TOGGLE_SIDEBAR_EXPANDED' });
//     };
//   };

export const toggleSidebarExpanded = () => {
  return (dispatch, getState, getFirebase) => {
    const { expanded } = getState().ui;

    localStorage.setItem('expanded', JSON.stringify(!expanded));
    //console.log('ACTION', !expanded);
    dispatch({
      type: 'TOGGLE_SIDEBAR_EXPANDED',
      expanded
    });
  };
};

export const setInitialSidebarExpanded = expanded => {
  //console.log('ACTION setInitialSidebarExpanded', test);
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: 'OPEN_SIDEBAR', expanded });
  };
};
