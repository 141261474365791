import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Style from './index.module.scss';
import * as ROUTES from './../../../../../constants/routes';

const Section = (props) => {
  const [corpoState, setCorpoState] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (corpoState === ROUTES.PWC_ROUTE_CODE)
      props.history.push(ROUTES.PWC_LANDING);
  };

  const handleChange = (e) => {
    setCorpoState(e.target.value);
  };

  return (
    <div className={['d-flex flex-wrap mx-auto', Style.Container].join(' ')}>
      <div className="col-md-4 col-lg-6 text-md-left d-flex justify-content-center align-items-center">
        <div className="text-center text-md-left">
          <h1>Galeries Smilzz</h1>
          <p>Entrez votre code d'entreprise pour accéder à vos galeries</p>
        </div>
      </div>
      <div className="col-md-8 col-lg-6 d-flex justify-content-center align-items-center">
        <form
          className={['m-0 m-md-4', Style.ButtonContainer].join(' ')}
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            className={Style.Input}
            name="corporate"
            type="text"
            placeholder="Code d'entreprise"
            onChange={handleChange}
          />
          <button className={Style.Button}>Valider</button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Section);
