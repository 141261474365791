import React from 'react';
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//import { signOut } from '../../../../../../store/actions/authActions';
import Avatar from 'react-avatar';
import AvatarImage from '../../../../../../assets/images/avatar.jpg';
import Styles from './index.module.scss';

const Section = ({ uid, signOut, profile }) => {
  const handleSignout = () => {
    console.log('sign out');
    // signOut().then(() => {

    // });
  };

  const userFirstName = profile.firstName ? (
    <div className="d-inline mr-2">{profile.firstName}</div>
  ) : null;

  const currentLink = uid ? (
    <React.Fragment>
      <NavItem className="d-sm-block d-md-none d-lg-none">
        <NavLink tag={Link} to="/upload">
          Upload
        </NavLink>
      </NavItem>
      <NavItem className="d-sm-block d-md-none d-lg-none">
        <NavLink tag={Link} to="/contact">
          Contact
        </NavLink>
      </NavItem>
      <NavItem className="d-sm-block d-md-none d-lg-none">
        <NavLink tag={Link} to="/dashboard">
          Dashboard
        </NavLink>
      </NavItem>

      <UncontrolledDropdown nav inNavbar className="d-none d-md-block">
        <DropdownToggle nav caret>
          {/* <Avatar name="Foo Bar" size="50" round={true} /> */}
          {userFirstName}
          <Avatar name="Foo Bar" size="40" round={true} src={AvatarImage} />
        </DropdownToggle>
        <DropdownMenu right className={['border-0', Styles.DropDown].join(' ')}>
          <div className="m-3 text-center">
            <Avatar
              className="mb-2"
              name="Foo Bar"
              size="40"
              round={true}
              src={AvatarImage}
            />
            <div className="font-weight-bold">
              {profile.firstName} {profile.lastName}
            </div>
            <div className="email">{profile.email}</div>
          </div>
          <DropdownItem divider />
          <DropdownItem>
            <Link tag={Link} to="/upload">
              Upload
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link tag={Link} to="/contact">
              Contact
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link tag={Link} to="/dashboard">
              Dashboard
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <div
              style={{ cursor: 'pointer' }}
              className=""
              onClick={handleSignout}
            >
              Déconnexion
            </div>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <NavItem>
        <NavLink tag={Link} to="/contact">
          Contact
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to="/signin">
          Me connecter
        </NavLink>
      </NavItem>
    </React.Fragment>
  );

  return <React.Fragment>{currentLink}</React.Fragment>;
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
