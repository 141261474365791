import React, { Component, Suspense } from 'react';

// ****** LAZY LOADING ROUTES ******
const LoadingMessage = () => <div className="text-center">"Loading..."</div>;

const lazyLoad = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <Suspense fallback={<LoadingMessage />}>
          <WrappedComponent {...this.props} />
        </Suspense>
      );
    }
  };
};

export default lazyLoad;
