import { lazy } from 'react';
import lazyLoad from './hoc/LazyLoad/LazyLoad';

const lazyAppLoading = lazy(() => import('./components/pages/AppLoading'));
export const AppLoadingPage = lazyLoad(lazyAppLoading);

const lazyAuth = lazy(() => import('./containers/Auth/Auth'));
export const AuthPage = lazyLoad(lazyAuth);

const lazyContact = lazy(() => import('./components/pages/Contact'));
export const ContactPage = lazyLoad(lazyContact);

const lazyNoPage = lazy(() => import('./components/pages/404'));
export const NoPage = lazyLoad(lazyNoPage);

const lazyPwcLanding = lazy(() =>
  import('./components/pages/Selection/PwcLanding')
);
export const PwcLandingPage = lazyLoad(lazyPwcLanding);

const lazyPwcThankYou = lazy(() =>
  import('./components/pages/Selection/PwcThankYou')
);
export const PwcThankYouPage = lazyLoad(lazyPwcThankYou);

const lazySelection = lazy(() => import('./containers/ImageSelection'));
export const SelectionPage = lazyLoad(lazySelection);

const lazySignIn = lazy(() => import('./components/pages/SignIn'));
export const SignInPage = lazyLoad(lazySignIn);

const lazyUpload = lazy(() => import('./containers/Upload/Upload'));
export const UploadPage = lazyLoad(lazyUpload);
