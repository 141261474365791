// const initState = {
//   projects: [
//     { id: 1, title: 'titre 01', content: 'hello toudou bein 01' },
//     { id: 2, title: 'titre 02', content: 'hello toudou bein 02' },
//     { id: 3, title: 'titre 03', content: 'hello toudou bein 03' }
//   ]
// };

const initState = {};

const pwcReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SAVE_GALERIES':
      //console.log('SAVE_GALERIES', action.galeries);
      //localStorage.setItem('galeries', JSON.stringify(action.galeries));
      return { ...state, galeries: action.galeries };

    case 'SET_UID':
      //console.log('setting uid', action.uid);
      return { ...state, MtcUid: action.uid };
    case 'SELECT_IMAGE':
      //console.log('created project', action.image);
      return state;
    case 'SELECT_IMAGE_PL':
      if (state.selectedPL && state.selectedPL.id === action.image.id) {
        // console.log(
        //   'pwcReducer SELECT_IMAGE_PL *** REMOVE ITEM FRM STRORAGE',
        //   action.image
        // );
        //localStorage.removeItem('selectedPL');
        return { ...state, selectedPL: null };
      } else {
        // console.log(
        //   'pwcReducer SELECT_IMAGE_PL *** ADD ITEM TO STRORAGE',
        //   action.image
        // );
        //localStorage.setItem('selectedPL', JSON.stringify(action.image));
        return { ...state, selectedPL: action.image };
      }

    case 'SEND_FINAL_SELECTION':
      //   localStorage.clear();
      //   localStorage.setItem('finalSelection', JSON.stringify(action.images));
      console.log('SEND_FINAL_SELECTION', action.images);
      return { ...state, finalSelection: action.images };

    case 'SEND_FINAL_SELECTION_ERR':
      console.log('SEND_FINAL_SELECTION_ERR', action.err);
      return { ...state, err: action.err };

    case 'SET_IMAGE_FROM_LOCAL_STORAGE_PL':
      return { ...state, selectedPL: action.image };

    case 'SET_IMAGE_FROM_LOCAL_STORAGE_PS':
      return { ...state, selectedPS: action.image };

    case 'SET_GALERIES_FROM_LOCAL_STORAGE':
      return { ...state, galeries: action.galeries };

    case 'SELECT_IMAGE_PS':
      if (state.selectedPS && state.selectedPS.id === action.image.id) {
        // console.log(
        //   'pwcReducer SELECT_IMAGE_PS *** REMOVE ITEM FRM STRORAGE',
        //   action.image
        // );
        //localStorage.removeItem('selectedPS');
        return { ...state, selectedPS: null };
      } else {
        // console.log(
        //   'pwcReducer SELECT_IMAGE_PS *** ADD ITEM TO STRORAGE',
        //   action.image
        // );
        //localStorage.setItem('selectedPS', JSON.stringify(action.image));
        return { ...state, selectedPS: action.image };
      }

    case 'SELECT_IMAGE_ERR':
      //console.log('created project error', action.err);
      return state;

    case 'SEND_CONTACT_FORM':
      console.log('SEND_CONTACT_FORM', action.contactData);
      return { ...state, userSentContactForm: true };

    case 'CRM_CONTACT_FORM_SENT':
      return { ...state, userSentContactForm: true };

    case 'SEND_CONTACT_FORM_ERR':
      console.log('SEND_CONTACT_FORM_ERR', action.err);
      return { ...state, err: action.err };

    default:
      return state;
  }
};

export default pwcReducer;
