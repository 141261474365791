// const initState = {
//   projects: [
//     { id: 1, title: 'titre 01', content: 'hello toudou bein 01' },
//     { id: 2, title: 'titre 02', content: 'hello toudou bein 02' },
//     { id: 3, title: 'titre 03', content: 'hello toudou bein 03' }
//   ]
// };

const initState = {
  expanded: JSON.parse(localStorage.getItem('expanded')) // || true
};

const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR_EXPANDED':
      //console.log('ACTION toggle sidebar', action.expanded);
      //localStorage.setItem('galeries', JSON.stringify(action.galeries));

      return { ...state, expanded: !action.expanded };

    case 'OPEN_SIDEBAR':
      // console.log('REDUCER OPEN_SIDEBAR', action.expanded);
      return { ...state, expanded: action.expanded };

    default:
      return state;
  }
};

export default uiReducer;
