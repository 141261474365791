//import authReducer from './authReducer';
//import projectReducer from './projectReducer';
import uiReducer from './uiReducer';
import pwcReducer from './pwcReducer';
// import userReducer from './userReducer';
// import dealReducer from './dealReducer';
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore'; // <- needed if using firestore

const rootReducer = combineReducers({
  //auth: authReducer,
  //project: projectReducer,
  firebase: firebaseReducer,
  pwc: pwcReducer,
  firestore: firestoreReducer,
  ui: uiReducer,
  //user: userReducer,
  //deal: dealReducer
});

export default rootReducer;
