import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Navbar from '../../components/ui/Nav/Navbar/Navbar';
import Footer from '../../components/ui/Nav/Footer/Footer';
import Sidebar from '../../components/ui/Nav/Sidebar02';
import * as ROUTES from '../../constants/routes';
import Expandable from '../../hoc/Expandable';

// STANDARD LAYOUT
export const Layout01Route = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => (
        <div>
          <Navbar />
          <div className="PageContainer mt-5">
            <Component {...props} />
          </div>
          <Footer />
        </div>
      )}
    />
  );
};

// NO FOOTER
export const Layout02Route = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => (
        <div>
          <Navbar />
          <div className="PageContainer mt-5">
            <Component {...props} />
          </div>
        </div>
      )}
    />
  );
};

// NO NAV - NO FOOTER
export const Layout03Route = ({ component: Component, ...rest }) => {
  return <Route {...rest} component={(props) => <Component {...props} />} />;
};
