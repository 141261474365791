// export const ACCOUNT = '/account';
// export const AUTH = '/auth';
export const CONTACT = '/contact';
// export const CREATE_PROJECT = '/create-project';
// export const DASHBOARD = '/dashboard';
// export const DASHBOARD_GALERIES = '/dashboard/galeries';
// export const DASHBOARD_IMAGES = '/dashboard/images';
// export const DASHBOARD_JOBS = '/dashboard/jobs';
// export const DASHBOARD_USERS = '/dashboard/users';
export const LANDING = '/';
// export const TEST = '/test';
// export const LOGOUT = '/logout';
// export const ORDERS = '/orders';
// export const PASSWORD_RESET = '/pw-reset';
export const PWC_LANDING = '/pwc-campagne-2020';
export const PWC_THANK_YOU = '/selection/thank-you';
export const SELECTION = '/selection';
export const SELECTION_COMPLETE = '/selection/complete';
export const SELECTION_PLAN_LARGE = '/selection/plan-large';
export const SELECTION_PLAN_SERRE = '/selection/plan-serre';
export const SIGN_IN = '/signin';
// export const SIGN_UP = '/signup';
// export const UPLOAD = '/upload';
// export const UPLOAD_COMPLETE = '/upload/complete';
// export const UPLOAD_IMAGES = '/upload/images';
//export const USERS = '/users';

export const PWC_ROUTE_CODE = '415174';
