// import React from 'react';
// import Styles from './index.module.scss';
// import { connect } from 'react-redux';
import React from 'react';
import Styles from './index.module.scss';
import { connect } from 'react-redux';

const Wrapper = ({ children, expanded }) => {
  const marginLeft = { marginLeft: `${expanded ? '240' : '64'}px` };
  //console.log('marginLeft', marginLeft);
  //const expandableStyle = {marginLeft: ${props => (props.expanded ? 240 : 64)}px;
  return (
    <div style={marginLeft} className={Styles.Main}>
      {children}
    </div>
  );
};

const mapStateToProps = state => {
  //console.log(state);
  return {
    expanded: state.ui.expanded //state.project.projects,
  };
};

export default connect(mapStateToProps)(Wrapper);
